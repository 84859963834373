































































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import JobFamily from '@/components/hszg/JobFamily.vue'
import JobFamilyGrid from '@/components/hszg/JobFamilyGrid.vue'
import Competence from '@/components/hszg/Competence.vue'
import { GET_JOB_FAMILIES } from '@/store/user/actions.type'
import { JobFamily as JobFamilyDataType } from '@/store/user/types'
@Component({
  components: { Competence, JobFamilyGrid, JobFamily }
})
export default class DreamJob extends Vue {
  @Action(GET_JOB_FAMILIES)
  public getJobFamilies: () => Promise<JobFamilyDataType[]>

  private selectedDreamJob = ''
  private jobFamilies: Array<JobFamilyDataType> = []
  private collapsed = false

  created (): void {
    this.loadData()
  }

  selectDreamJob (id: string): void {
    this.selectedDreamJob = id
  }

  get dreamJob (): object {
    return this.jobFamilies.filter(jobFamily => jobFamily.id === this.selectedDreamJob)[0]
  }

  private loadData (): void {
    this.getJobFamilies().then((data: JobFamilyDataType[]) => {
      this.jobFamilies = data
    })
  }
}
