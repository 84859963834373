









































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
/*
@group HSZG
For showing (all) job families in a grid as used on dream job page
*/
export default class JobFamilyGrid extends Vue {
  // the job family
  @Prop() family: object

  private collapsed = false

  toggleCollapse (id: string): void {
    this.$root.$emit('bv::toggle::collapse', 'job-family-collapse-' + id)
    this.collapsed = !this.collapsed
  }

  selectDreamJob (id: string): void {
    this.$emit('select:dreamjob', id)
  }
}
